<template>
  <ShablykinoVillage />
</template>

<script>
import ShablykinoVillage from '@/components/ShablykinoVillage.vue';
export default {
  name: 'App',
  components: {
    ShablykinoVillage
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: -1;
  background-color: #F5F3ED;
}

#app {
  height: 100%;
  width: 100%;
}
</style>
